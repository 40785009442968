const postFilters = ($) => {
    let currentRequest = null;

    const fetchPosts = (termType = '', termId = '') => {
        // Abort any running request
        if (currentRequest) {
            currentRequest.abort();
        }

        $('#posts-grid').addClass('is-loading');

        const queryString = termType && termId ? `?${termType}=${termId}` : '';

        currentRequest = $.ajax({
            url: `${wpApiSettings.root}wp/v2/posts-tax${queryString}`,
            method: 'GET',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', wpApiSettings.nonce);
            }
        })
            .done(function (response) {
                setTimeout(() => {
                    $('#posts-grid').html(response.html);
                }, 1000); // Adding delay for smoother transition
            })
            .always(function () {
                currentRequest = null;
                setTimeout(() => {
                    $('#posts-grid').removeClass('is-loading');
                }, 1000); // Adding delay for smoother transition
            });
    };

    const activateFilter = ($el) => {
        $el.addClass('is-active');
        $el.siblings().removeClass('is-active');
    };

    const resetFilters = () => {
        $('.lister-filter__filter-button').removeClass('is-active');
        fetchPosts(); // Fetch posts without filters
    };

    $(document).on('click', '.lister-filter__filter-button', function (e) {
        e.preventDefault();

        const $el = $(this);
        activateFilter($el);

        const termType = $el.data('term_type');
        const termId = $el.data('term_id');

        fetchPosts(termType, termId);
    });

    $(document).on('click', '.lister-filter__reset-button', function (e) {
        e.preventDefault();
        resetFilters();
    });
};

export default postFilters;