
let intervalId = null;
let observer = null;

const setupDesktopMode = () => {
    const cards = document.querySelectorAll('.vorderman__stat');
    intervalId = setInterval(() => {
        const randomCard = cards[Math.floor(Math.random() * cards.length)];
        randomCard?.classList.toggle('flipped');
    }, 4000);
};

const setupMobileMode = () => {
    observer = new IntersectionObserver(
        entries => entries.forEach(entry =>
            entry.target.classList.toggle('flipped', entry.intersectionRatio > 0.5)
        ),
        { rootMargin: '50% 0px -50% 0px' }
    );

    document.querySelectorAll('.vorderman__stat').forEach(card => {
        observer.observe(card);
    });
};

const bindUIEvents = () => {
    const handleViewportChange = () => {
        cleanup();
        window.innerWidth >= 768 ? setupDesktopMode() : setupMobileMode();
    };

    handleViewportChange();
    window.addEventListener('resize', handleViewportChange);
};

const cleanup = () => {
    if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
    }

    if (observer) {
        document.querySelectorAll('.vorderman__stat').forEach(card => {
            observer.unobserve(card);
        });
        observer = null;
    }
};

const shouldLoad = () => {
    return document.querySelectorAll('.vorderman__stat').length > 0;
};

const initFlipManager = () => {
    if (shouldLoad()) bindUIEvents();
};

export default initFlipManager;