import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';

const Ticker = () => {

    const shouldInit = () => {
        return document.querySelectorAll('.ticker__carousel.swiper').length;
    };

    const init = () => {
        if (!shouldInit()) return;

        const swiper = new Swiper('.ticker__carousel.swiper', {
            modules: [Autoplay],
            freeMode: true,
            loop: true,
            slidesPerView: 2,
            speed: 5000,
            spaceBetween: 40,
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
            },
            allowTouchMove: false,
            breakpoints: {
                740: {
                    slidesPerView: 7,
                    spaceBetween: 80
                },
            },
        });
    };

    return {
        init
    };
}

export default Ticker;