import setupLottieAnimation from "./lottieHandler";

export const watchScroll = () => {
    let lastScrollTop = window.scrollY;
    let ticking = false;

    window.addEventListener('scroll', () => {
        const currentScroll = window.scrollY;

        if (!ticking) {
            requestAnimationFrame(() => {
                const isScrollingDown = currentScroll > lastScrollTop;

                if (currentScroll > 500) {
                    document.body.classList.toggle('scrolling-down', isScrollingDown);
                    document.body.classList.toggle('scrolling-up', !isScrollingDown);
                } else {
                    document.body.classList.remove('scrolling-down');
                    document.body.classList.remove('scrolling-up');
                }

                lastScrollTop = currentScroll;
                ticking = false;
            });
            ticking = true;
        }
    }, {passive: true});
};

export const hasLightNext = () => {
    document.querySelectorAll('.component.padding-regular').forEach(el => {
        if (el.nextElementSibling?.matches('.component.light')) {
            el.classList.add('has-light-next');
        } else {
            el.classList.add('has-dark-next');
        }
    });
}

export const teamSliderBioToggle = () => {
    document.addEventListener('click', (event) => {
        const toggleButton = event.target.closest('.team-slider__bio-toggle');

        if (toggleButton) {
            const teamEntry = toggleButton.closest('.team-slider__entry');

            if (teamEntry) {
                teamEntry.classList.toggle('bio-visible');
            }
        }
    });
}

export const activePostsGrid = () => {
    const postsGrid = document.getElementById('posts-grid');

    // Only initialize if posts grid exists
    if (!postsGrid) return;

    let observer;

    // Function to handle visibility tracking
    function trackVisiblePostCards() {
        // Only run on mobile/tablet
        if (window.innerWidth >= 991) {
            removeAllActiveClasses();
            return;
        }

        const postCards = postsGrid.querySelectorAll('.post-card');
        let topVisibleCard = null;
        let topVisiblePosition = Infinity;

        postCards.forEach(card => {
            const rect = card.getBoundingClientRect();
            const visible = rect.top < window.innerHeight && rect.bottom > 0;

            if (visible && rect.top < topVisiblePosition && rect.top > -rect.height/2) {
                topVisiblePosition = rect.top;
                topVisibleCard = card;
            }
        });

        removeAllActiveClasses();

        if (topVisibleCard) {
            const basicInfo = topVisibleCard.querySelector('.post-card__basic-info');
            if (basicInfo) {
                basicInfo.classList.add('active');
            }
        }
    }

    function removeAllActiveClasses() {
        postsGrid.querySelectorAll('.post-card__basic-info.active').forEach(basicInfo => {
            basicInfo.classList.remove('active');
        });
    }

    // Watch for DOM changes in the posts grid
    function initializeObserver() {
        if (observer) {
            observer.disconnect();
        }

        observer = new MutationObserver(() => {
            trackVisiblePostCards();
        });

        observer.observe(postsGrid, {
            childList: true,
            subtree: true
        });
    }

    // Initialize
    initializeObserver();

    // Track on scroll
    window.addEventListener('scroll', () => {
        requestAnimationFrame(trackVisiblePostCards);
    });

    // Track on resize
    window.addEventListener('resize', () => {
        requestAnimationFrame(trackVisiblePostCards);
    });

    // Initial check
    trackVisiblePostCards();
}

export const togglePostContent = () => {
    const toggleButtons = document.querySelectorAll('.case-studies-main-content-toggle');

    if (!toggleButtons.length) {
        console.warn('No toggle buttons found');
        return;
    }

    toggleButtons.forEach(toggleButton => {
        const contentWrapper = document.querySelector(`#${toggleButton.getAttribute('aria-controls')}`);

        if (!contentWrapper) {
            console.warn(`Content wrapper for button with ID ${toggleButton.id} not found`);
            return;
        }

        const handleToggle = () => {
            const isCurrentlyExpanded = toggleButton.getAttribute('aria-expanded') === 'true';
            toggleButton.setAttribute('aria-expanded', (!isCurrentlyExpanded).toString());

            if (!contentWrapper.classList.contains('is-active')) {
                // Expand
                contentWrapper.style.height = '0px';
                contentWrapper.classList.add('is-active');
                const height = contentWrapper.scrollHeight;
                contentWrapper.style.height = height + 'px';
            } else {
                // Collapse
                contentWrapper.style.height = '0px';
                contentWrapper.addEventListener('transitionend', () => {
                    contentWrapper.classList.remove('is-active');
                }, { once: true });
            }
        };

        toggleButton.addEventListener('click', handleToggle);

        const startExpanded = toggleButton.getAttribute('aria-expanded') === 'true';
        if (startExpanded) {
            contentWrapper.classList.add('is-active');
            contentWrapper.style.height = contentWrapper.scrollHeight + 'px';
        }
    });
};

export const copyLink = () => {
    document.querySelectorAll('.copy-link-button').forEach(button => {
        button.addEventListener('click', async (e) => {
            e.preventDefault();

            try {
                await navigator.clipboard.writeText(window.location.href);

                // Optional: Show feedback to user
                const originalText = button.textContent;
                button.textContent = 'Copied!';

                // Reset button text after 2 seconds
                setTimeout(() => {
                    button.textContent = originalText;
                }, 2000);
            } catch (err) {
                console.error('Failed to copy URL:', err);
                // Fallback for older browsers
                const textarea = document.createElement('textarea');
                textarea.value = window.location.href;
                textarea.style.position = 'fixed';  // Avoid scrolling
                document.body.appendChild(textarea);
                textarea.select();

                try {
                    document.execCommand('copy');
                    button.textContent = 'Copied!';
                    setTimeout(() => {
                        button.textContent = originalText;
                    }, 2000);
                } catch (err) {
                    console.error('Fallback copy failed:', err);
                } finally {
                    document.body.removeChild(textarea);
                }
            }
        });
    });
}

export const mobileNavToggle = () => {
    const toggleButton = document.querySelector('.mobile-nav-toggle');
    const menuItems = document.querySelectorAll('#menu-main-menu .menu-item');

    if (!toggleButton) return;

    toggleButton.addEventListener('click', () => {
        const isExpanded = toggleButton.getAttribute('aria-expanded') === 'true';
        toggleButton.setAttribute('aria-expanded', (!isExpanded).toString());
        document.body.classList.toggle('mobile-menu-open', !isExpanded);
    });

    menuItems.forEach(item => {
        item.addEventListener('click', () => {
            toggleButton.setAttribute('aria-expanded', 'false');
            document.body.classList.remove('mobile-menu-open');
        });
    });
};

export const autoplayLotties = () => {
    document.querySelectorAll('.lottie-animation-canvas.autoplay-in-view').forEach((animationContainer) => {
        const animationType = animationContainer.dataset.animationType;

        if (!animationType) {
            console.error('Animation type not specified for container:', animationContainer);
            return;
        }

        setupLottieAnimation(animationContainer, {
            loop: false,
            autoplay: false, // This will be overridden by `autoplay-in-view` if applicable
        });
    });
};

export const monitorSectionsInViewport = () => {
    if (window.innerWidth < 992) return;

    const sections = document.querySelectorAll('.component');

    if (!sections.length) {
        console.warn('No .component elements found to monitor');
        return;
    }

    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('in-viewport');
                    // Stop observing this element after the class is added
                    observer.unobserve(entry.target);
                }
            });
        },
        {
            rootMargin: '-20% 0px -20% 0px',
            threshold: 0
        }
    );

    // Initial check: Mark all sections that are already past the trigger point
    sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        // Check if the section is already scrolled past the trigger point
        const isPastTriggerPoint = rect.top < viewportHeight * 0.75;

        if (isPastTriggerPoint) {
            section.classList.add('in-viewport');
        }

        // Observe each section for future visibility changes
        observer.observe(section);
    });
};

export const caseStudyAnimations = () => {
    const animationInstances = new Map(); // To store animation control objects by element

    document.querySelectorAll('.case-studies-card').forEach((entry) => {
        const animationContainer = entry.querySelector('.lottie-animation-canvas');

        if (animationContainer) {
            // Initialize the Lottie animation and store the control object
            setupLottieAnimation(animationContainer).then((controls) => {
                animationInstances.set(entry, controls); // Map entry to the animation controls
            });
        }
        entry.addEventListener('mouseover', () => {
            //entry.classList.add('team-grid__entry--active', 'is-animated'); // Activate this entry

            // Play the associated animation
            const controls = animationInstances.get(entry);
            if (controls) {
                controls.play(); // Play the animation
            }
        });
    });
}

export const updateActiveNav = () => {
    document.body.addEventListener("click", function (event) {
        const clickedLink = event.target.closest("#menu-main-menu .menu-item a");

        if (clickedLink) {
            setActiveNav(clickedLink);
        }
    });

    setActiveNav(document.querySelector("#menu-main-menu .menu-item a[href='" + window.location.pathname + "']"));
};

const setActiveNav = (clickedLink) => {
    if (!clickedLink) return;

    const menuItems = document.querySelectorAll("#menu-main-menu .menu-item");

    menuItems.forEach(item => {
        item.classList.remove("current-menu-item", "current_page_item");
    });

    clickedLink.closest(".menu-item").classList.add("current-menu-item", "current_page_item");
}

export const setCookie = function(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
