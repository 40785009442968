const hideSeek = () => {
    const init = () => {
        const triggers = document.querySelectorAll('.accordion-trigger');

        triggers.forEach(trigger => {
            trigger.addEventListener('click', () => {
                const targetId = trigger.getAttribute('data-aside-image');
                const targetElement = document.getElementById(targetId);

                if (targetElement) {
                    // Remove 'is-active' from all siblings
                    const siblings = [...targetElement.parentElement.children].filter(child => child !== targetElement);
                    siblings.forEach(sibling => sibling.classList.remove('is-active'));

                    // Toggle the target element
                    targetElement.classList.toggle('is-active');
                }
            });
        });
    }

    return {
        init
    }
}

export default hideSeek;