const defaultOptions = {
    renderer: 'svg',
    loop: false,
    autoplay: false,
};

const animationCache = new Map(); // Cache to store resolved animation data
const pendingPromises = new Map(); // Cache for in-progress fetch requests

const setupLottieAnimation = async (container, options = {}) => {
    if (!container?.dataset?.animation) {
        throw new Error('Container element with animation URL is required.');
    }

    const animationUrl = container.dataset.animation;
    const animationData = await fetchAnimationData(animationUrl); // Fetch animation data (cached or pending)
    const speed = Math.max(0.1, parseFloat(container.dataset.animationSpeed) || 1);
    const loop = container.dataset.loop === '1'; // Read data-loop attribute (default to false)
    const animationInstance = initializeAnimation(container, animationData, { ...options, loop }, speed);

    setupIntersectionObserver(container, animationInstance);

    // Return an object with control functions for external interaction
    return {
        play: () => animationInstance.play(),
        pause: () => animationInstance.pause(),
        stop: () => animationInstance.stop(),
        reset: () => animationInstance.goToAndStop(0, true),
        setSpeed: (newSpeed) => animationInstance.setSpeed(Math.max(0.1, newSpeed)),
        getInstance: () => animationInstance, // For advanced direct control
    };
};

const fetchAnimationData = async (url) => {
    if (animationCache.has(url)) {
        return animationCache.get(url);
    }

    if (pendingPromises.has(url)) {
        return pendingPromises.get(url);
    }

    const fetchPromise = fetch(url)
        .then(response => {
            if (!response.ok) throw new Error(`Failed to fetch: ${url}`);

            const contentType = response.headers.get('content-type');
            if (!contentType?.includes('application/json')) {
                console.warn(`Unexpected content type: ${contentType}`);
            }

            return response.text();
        })
        .then(text => {
            try {
                const animationData = JSON.parse(text);
                animationCache.set(url, animationData); // Cache the animation data
                return animationData;
            } catch (e) {
                console.error('Failed to parse animation data:', text.slice(0, 100));
                throw new Error('Invalid JSON in animation file');
            }
        })
        .catch(error => {
            console.error('Animation fetch error:', error);
            throw error;
        })
        .finally(() => {
            pendingPromises.delete(url); // Clean up pending promise cache
        });

    pendingPromises.set(url, fetchPromise); // Cache the pending fetch promise
    return fetchPromise;
};

const initializeAnimation = (container, animationData, options, speed) => {
    const instance = lottie.loadAnimation({
        container,
        ...defaultOptions,
        ...options,
        animationData,
    });

    instance.setSpeed(speed);
    return instance;
};

const setupIntersectionObserver = (container, instance) => {
    if (!container.classList.contains('autoplay-in-view')) return;

    const observer = new IntersectionObserver(
        entries => entries.forEach(entry =>
            entry.isIntersecting ? instance.play() : false
        ),
        {
            root: null,
            rootMargin: '0px 0px -25% 0px',
            threshold: 0,
        }
    );

    observer.observe(container);
};

export const resetAnimation = (instance) => instance?.goToAndStop(0, true);

export default setupLottieAnimation;
