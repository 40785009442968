import Swiper from 'swiper';

const MediaSlider = () => {
    let swiper = null;

    const shouldInit = () => {
        return document.querySelectorAll('.media-slider__container.swiper').length;
    };

    const destroy = () => {
        if (swiper) {
            swiper.destroy(true, true);
            swiper = null;
        }
    };

    const init = () => {
        if (!shouldInit()) return;

        destroy();

        const swiperContainer = document.querySelector('.media-slider__container.swiper');
        const totalSlides = swiperContainer ? swiperContainer.querySelectorAll('.swiper-slide').length : 0;

        const swiper = new Swiper('.media-slider__container.swiper', {
            loop: totalSlides > 3,
            slidesPerView: 1,
            spaceBetween: 20,
            centeredSlides: true,
            speed: 400,
            navigation: {
                nextEl: ".media-swiper__arrow.swiper-pagination__button-next",
                prevEl: ".media-swiper__arrow.swiper-pagination__button-prev",
                disabledClass: 'swiper-button-disabled',
            },
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
                waitForTransition: true
            },
            breakpoints: {
                740: {
                    slidesPerView: 1.5,
                },
            }
        });

        const nextButton = document.querySelector('.media-swiper__arrow.swiper-pagination__button-next');
        const prevButton = document.querySelector('.media-swiper__arrow.swiper-pagination__button-prev');

        if (nextButton) {
            nextButton.addEventListener('click', () => {
                swiper.slideNext();
            });
        }

        if (prevButton) {
            prevButton.addEventListener('click', () => {
                swiper.slidePrev();
            });
        }

        return swiper;
    };

    return {
        init,
        destroy
    };
};

export default MediaSlider;