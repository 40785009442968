//import Swup from 'swup';
//import SwupA11yPlugin from '@swup/a11y-plugin';
import focusWithin from 'focus-within';
import SuperheroSlider from './modules/superhero';
import MediaSlider from './modules/media-slider';
import TeamSlider from './modules/team-slider';
import Ticker from './modules/ticker';
import IconGridSlider from './modules/icon-grid-slider';
import CardsSlider from './modules/cards-slider';
import initTeamGrid from './modules/team-grid';
import TestimonialSlider from './modules/testimonials-slider';
import accordionAutomaticInit from './modules/accordion';
import TabsAutomatic from './modules/accessibilityTabs';
//import setupLottieAnimation from './modules/lottieHandler';
import initFlipManager from './modules/flipManager';
import hideSeek from "./modules/hide-seek";
import postFilters from "./modules/postFilters"
import initHubSpotForm from './modules/hubspot';


import {
    watchScroll, autoplayLotties,
    hasLightNext, teamSliderBioToggle,
    activePostsGrid, caseStudyAnimations,
    togglePostContent, updateActiveNav,
    copyLink, mobileNavToggle, monitorSectionsInViewport, setCookie
} from './modules/helpers';

jQuery(document).ready(function ($) {
    // Remove no-js class and replace with js.
    $('html').addClass('js').removeClass('no-js');
    window.setCookie = setCookie;

    // window.swup = new Swup({
    //     native: true,
    //     plugins: [new SwupA11yPlugin()]
    // });

    // :focus-within polyfill
    focusWithin(document);
    watchScroll();

    accordionAutomaticInit();
    TabsAutomatic();
    SuperheroSlider().init();
    MediaSlider().init();
    TeamSlider().init();
    TestimonialSlider().init();
    Ticker().init();
    IconGridSlider().init();
    CardsSlider().init();
    hideSeek().init();
    initTeamGrid();
    initFlipManager();

    //adhoc JS
    hasLightNext();
    teamSliderBioToggle();
    activePostsGrid();
    togglePostContent();
    copyLink();
    mobileNavToggle();
    autoplayLotties();
    monitorSectionsInViewport();
    caseStudyAnimations();
    updateActiveNav();

    postFilters($);
    initHubSpotForm();

    //todo: evaluate this block and remove duplication of above
    // swup.hooks.on('page:view', () => {
    //     accordionAutomaticInit();
    //     TabsAutomatic();
    //     SuperheroSlider().init();
    //     MediaSlider().init();
    //     TeamSlider().init();
    //     TestimonialSlider().init();
    //     Ticker().init();
    //     IconGridSlider().init();
    //     CardsSlider().init();
    //     hideSeek().init();
    //     initTeamGrid();
    //     initFlipManager();
    //
    //     //adhoc JS
    //     hasLightNext();
    //     teamSliderBioToggle();
    //     activePostsGrid();
    //     togglePostContent();
    //     copyLink();
    //     autoplayLotties();
    //     monitorSectionsInViewport();
    //     caseStudyAnimations();
    //
    //     initHubSpotForm();
    // });
});
