import Swiper from 'swiper';

const CardsSlider = () => {
    const init = () => {
        const carousels = document.querySelectorAll('.cards-slider__carousel');
        if (!carousels.length) return;

        carousels.forEach((carousel) => {
            const swiper = new Swiper(carousel, {
                slidesPerView: 1,
                spaceBetween: 40,
                centeredSlides: false,
                loop: false,
                autoplay: false,
                speed: 1000,
                slidesOffsetAfter: 200,
                breakpoints: {
                    1024: {
                        slidesPerView: 1.2,
                    },
                    1180: {
                        centeredSlides: false,
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    1440: {
                        centeredSlides: false,
                        slidesPerView: 1.6,
                    }
                }
            });

            const prevButton = carousel.querySelector('.swiper-pagination__button-prev');
            const nextButton = carousel.querySelector('.swiper-pagination__button-next');

            const updateButtonStates = () => {
                prevButton.disabled = swiper.isBeginning;
                nextButton.disabled = swiper.isEnd;

                prevButton.classList.toggle('swiper-button-disabled', swiper.isBeginning);
                nextButton.classList.toggle('swiper-button-disabled', swiper.isEnd);
            };

            prevButton.addEventListener('click', () => {
                swiper.slidePrev();
            });

            nextButton.addEventListener('click', () => {
                swiper.slideNext();
            });

            // Update button states initially and after each slide change
            updateButtonStates();
            swiper.on('slideChange', updateButtonStates);
        });
    }

    return {
        init
    }
}

export default CardsSlider;