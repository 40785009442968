import Swiper from 'swiper';

const IconGridSlider = () => {
    const shouldInit = () => {

        const isMobile = window.matchMedia('(max-width: 991px)').matches;

        return isMobile && document.querySelectorAll('.icon-grid-slider__carousel').length;
    }

    const init = () => {
        if (!shouldInit()) return;

        const swiper = new Swiper('.icon-grid-slider__carousel', {
            slidesPerView: 1.3, // Default for mobile
            spaceBetween: 20,
            centeredSlides: true,
        });


        const carouselWrapper = document.querySelector('.icon-grid-slider__carousel');
        const prevButton = carouselWrapper.querySelector('.swiper-pagination__button-prev');
        const nextButton = carouselWrapper.querySelector('.swiper-pagination__button-next');

        const updateButtonStates = () => {
            prevButton.disabled = swiper.isBeginning;
            nextButton.disabled = swiper.isEnd;

            prevButton.classList.toggle('swiper-button-disabled', swiper.isBeginning);
            nextButton.classList.toggle('swiper-button-disabled', swiper.isEnd);
        };

        prevButton.addEventListener('click', () => {
            swiper.slidePrev();
        });

        nextButton.addEventListener('click', () => {
            swiper.slideNext();
        });

        // Update button states initially and after each slide change
        updateButtonStates();
        swiper.on('slideChange', updateButtonStates);
    }

    return {
        init
    }
}

export default IconGridSlider;