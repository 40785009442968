import setupLottieAnimation from './lottieHandler';

const animationInstances = new Map(); // To store animation control objects by element

const bindUIEvents = () => {
    document.querySelectorAll('.team-grid__entry').forEach((entry) => {
        const animationContainer = entry.nextElementSibling?.querySelector('.lottie-animation-canvas');

        if (animationContainer) {
            // Initialize the Lottie animation and store the control object
            setupLottieAnimation(animationContainer).then((controls) => {
                animationInstances.set(entry, controls); // Map entry to the animation controls
            });
        }

        // Add click event listener for toggling animation and state
        entry.addEventListener('click', () => {
            if (entry.classList.contains('team-grid__entry--active')) {
                hideExpandedBios(); // Close the active entry
                return;
            }

            hideExpandedBios(); // Reset all other entries
            entry.classList.add('team-grid__entry--active', 'is-animated'); // Activate this entry

            // Play the associated animation
            const controls = animationInstances.get(entry);
            if (controls) {
                controls.play(); // Play the animation
            }
        });
    });
};

const hideExpandedBios = () => {
    document.querySelectorAll('.team-grid__entry--active').forEach((entry) => {
        entry.classList.remove('team-grid__entry--active', 'is-animated'); // Remove active classes

        // Reset the associated animation
        const controls = animationInstances.get(entry);
        if (controls) {
            controls.reset(); // Reset the animation to the first frame
        }
    });
};

const shouldLoad = () => {
    return document.querySelectorAll('.team-grid').length > 0;
};

const initTeamGrid = () => {
    if (shouldLoad()) bindUIEvents();
};

export default initTeamGrid;
