import Swiper from 'swiper';
import { Autoplay, Pagination } from 'swiper/modules';

const TestimonialSlider = () => {
    const init = () => {
        const carousels = document.querySelectorAll('.testimonials-slider__carousel');
        if (!carousels.length) return;

        carousels.forEach((carousel) => {
            const swiper = new Swiper(carousel, {
                slidesPerView: 'auto',
                spaceBetween: 40,
                loop: false,
                autoplay: {
                    delay: 10000,
                    disableOnInteraction: true,
                    waitForTransition: true
                },
                slidesOffsetAfter: 0,
                modules: [Autoplay, Pagination],
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                    renderBullet: function (index, className) {
                        return `<button class="${className}" type="button" aria-label="Go to slide ${index + 1}"></button>`;
                    }
                }
            });
        });
    }

    return {
        init
    }
}

export default TestimonialSlider;
