'use strict';
class Accordion {
    constructor(domNode) {
        this.rootEl = domNode;
        this.buttonEl = this.rootEl.querySelector('button[aria-expanded]');

        const controlsId = this.buttonEl.getAttribute('aria-controls');
        this.contentEl = document.getElementById(controlsId);

        this.accordionContainer = this.rootEl.closest('.accordion__hide-seek');

        this.isOpen = this.buttonEl.getAttribute('aria-expanded') === 'true';
        this.accordions = [];
        this.autoAdvanceDelay = null;
        this.autoAdvanceTimeout = null;
        this.isInView = false;

        // Initialize intersection observer
        this.observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    this.isInView = entry.isIntersecting;
                    if (this.isInView && this.isOpen && this.autoAdvanceDelay) {
                        this.setupAutoAdvance();
                    } else if (!this.isInView && this.autoAdvanceTimeout) {
                        clearTimeout(this.autoAdvanceTimeout);
                        this.autoAdvanceTimeout = null;
                    }
                });
            },
            {
                threshold: 0.5 // Trigger when 50% of the element is visible
            }
        );

        // Start observing the accordion container
        if (this.accordionContainer) {
            this.observer.observe(this.accordionContainer);
        }

        // Just bind it directly here
        this.buttonEl.addEventListener('click', () => this.onButtonClick());
    }

    onButtonClick() {
        // Clear timeouts and disable auto-advance
        if (this.autoAdvanceTimeout) {
            clearTimeout(this.autoAdvanceTimeout);
            this.autoAdvanceTimeout = null;
        }

        // Remove auto-advance from all accordions and mark containers for instant animation
        this.accordions.forEach(accordion => {
            accordion.autoAdvanceDelay = null;
            if (accordion.autoAdvanceTimeout) {
                clearTimeout(accordion.autoAdvanceTimeout);
                accordion.autoAdvanceTimeout = null;
            }
            // Add class for instant fill to all accordion containers
            if (accordion.accordionContainer) {
                accordion.accordionContainer.classList.add('instant-fill');
            }
        });

        this.toggle(!this.isOpen);
    }

    setAnimationDuration(duration) {
        if (this.accordionContainer) {
            const seconds = duration / 1000;
            this.accordionContainer.style.setProperty('--animation-duration', `${seconds}s`);
        }
    }

    toggle(open) {
        if (open === this.isOpen) return;

        this.isOpen = open;
        this.buttonEl.setAttribute('aria-expanded', `${open}`);

        if (open) {
            this.accordions.forEach(accordion => {
                if (accordion !== this) {
                    accordion.close();
                }
            });

            this.contentEl.classList.remove('closing');
            this.contentEl.removeAttribute('hidden');

            if (this.autoAdvanceDelay && this.isInView) {
                this.setAnimationDuration(this.autoAdvanceDelay);
                this.setupAutoAdvance();
            }
        } else {
            this.contentEl.classList.add('closing');
            this.contentEl.setAttribute('hidden', '');
            setTimeout(() => {
                this.contentEl.classList.remove('closing');
            }, 290);
        }
    }

    setupAutoAdvance() {
        if (this.autoAdvanceTimeout) {
            clearTimeout(this.autoAdvanceTimeout);
        }

        // Only set up auto-advance if the accordion is in view
        if (this.isInView) {
            this.autoAdvanceTimeout = setTimeout(() => {
                const currentIndex = this.accordions.indexOf(this);
                const nextIndex = (currentIndex + 1) % this.accordions.length;
                this.accordions[nextIndex].open();
            }, this.autoAdvanceDelay);
        }
    }

    open() {
        this.toggle(true);
    }

    close() {
        this.toggle(false);
    }

    // Clean up method to remove observer when accordion is destroyed
    destroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
        if (this.autoAdvanceTimeout) {
            clearTimeout(this.autoAdvanceTimeout);
        }
    }
}

const accordionAutomaticInit = () => {
    const accordionGroups = document.querySelectorAll('.accordions__outer');

    accordionGroups.forEach((group) => {
        const accordionElements = group.querySelectorAll('.accordion h3');
        const accordionInstances = [];

        accordionElements.forEach(el => {
            const instance = new Accordion(el);
            accordionInstances.push(instance);
        });

        const autoAdvanceDelay = group.dataset.autoAdvance ?
            parseInt(group.dataset.autoAdvance) * 1000 : null;

        accordionInstances.forEach(accordion => {
            accordion.accordions = accordionInstances;
            accordion.autoAdvanceDelay = autoAdvanceDelay;
        });

        if (autoAdvanceDelay && accordionInstances.length > 0) {
            accordionInstances[0].open();
        }
    });
}

export default accordionAutomaticInit;