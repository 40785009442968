import Swiper from 'swiper';
import { Autoplay, EffectFade } from 'swiper/modules';

const SuperheroSlider = () => {
    let swiper = null;
    let autoplayEnabled = true;
    const paginationElement = document.querySelector('.superhero-swiper-pagination');

    const updateCustomPagination = (swiper) => {
        const buttons = paginationElement.querySelectorAll('button');

        buttons.forEach((button, index) => {
            if (index === swiper.realIndex) {
                button.classList.add('active');
            } else {
                button.classList.remove('active');
            }
        });
    };

    const initPagination = () => {
        const buttons = paginationElement.querySelectorAll('button');

        buttons.forEach(button => {
            button.addEventListener('click', () => {
                const slideIndex = parseInt(button.getAttribute('data-slide-index'));
                console.log('Button index:', slideIndex, 'Current realIndex:', swiper.realIndex);
                swiper.slideToLoop(slideIndex);
            });
        });
    };

    const initPlayControls = () => {
        const playPauseButton = document.querySelector('.superhero__play-pause-button');

        playPauseButton.addEventListener('click', () => {
            if (autoplayEnabled) {
                swiper.autoplay.stop();
                autoplayEnabled = false;
                playPauseButton.classList.add('is-paused');
            } else {
                swiper.autoplay.start();
                autoplayEnabled = true;
                playPauseButton.classList.remove('is-paused');
            }
        });
    };

    const shouldInit = () => {
        return document.querySelectorAll('.superhero__carousel.swiper').length;
    };

    const init = () => {
        if (!shouldInit()) return;

        swiper = new Swiper('.superhero__carousel.swiper', {
            modules: [Autoplay, EffectFade],
            effect: "fade",
            loop: true,
            slidesPerView: 1,
            speed: 1000,
            spaceBetween: 0,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
                waitForTransition: true
            },
            on: {
                slideChange: function() {
                    updateCustomPagination(this);
                },
            }
        });

        initPagination();
        initPlayControls();
        updateCustomPagination(swiper);

        return swiper;
    };

    return {
        init
    };
};

export default SuperheroSlider;
