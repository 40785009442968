'use strict';

class TabsAutomatic {
    constructor(groupNode) {
        this.tablistNode = groupNode;
        this.tabs = Array.from(this.tablistNode.querySelectorAll('[role=tab]'));
        this.tabpanels = [];
        this.firstTab = null;
        this.lastTab = null;

        // Add selector-ready class to the wrapper div with null check
        const tabsWrapper = this.tablistNode.closest('.tabs');
        if (tabsWrapper) {
            tabsWrapper.classList.add('selector-ready');
        }

        for (var i = 0; i < this.tabs.length; i += 1) {
            var tab = this.tabs[i];
            var tabpanel = document.getElementById(tab.getAttribute('aria-controls'));

            // Add error checking
            if (!tabpanel) {
                console.warn(`No tabpanel found for tab with aria-controls="${tab.getAttribute('aria-controls')}"`);
                continue; // Skip this tab if no panel found
            }

            tab.tabIndex = -1;
            tab.setAttribute('aria-selected', 'false');
            this.tabpanels.push(tabpanel);

            // Add is-hidden to all panels except first one
            if (i > 0) {
                tabpanel.classList.add('is-hidden');
            }

            tab.addEventListener('keydown', this.onKeydown.bind(this));
            tab.addEventListener('click', this.onClick.bind(this));

            if (!this.firstTab) {
                this.firstTab = tab;
            }
            this.lastTab = tab;
        }

        if (this.firstTab) {
            this.setSelectedTab(this.firstTab, false);
        }
    }


    setSelectedTab(currentTab, setFocus) {
        if (typeof setFocus !== 'boolean') {
            setFocus = true;
        }

        for (var i = 0; i < this.tabs.length; i += 1) {
            const tab = this.tabs[i];
            const panel = this.tabpanels[i];

            if (currentTab === tab) {
                tab.setAttribute('aria-selected', 'true');
                tab.removeAttribute('tabindex');
                panel.classList.remove('fade-out');
                panel.offsetHeight; // Force reflow for CSS animations
                panel.classList.remove('is-hidden');

                if (setFocus) {
                    tab.focus();
                }
            } else {
                tab.setAttribute('aria-selected', 'false');
                tab.tabIndex = -1;

                // Add fade-out for animation
                panel.classList.add('fade-out');

                // Apply is-hidden after animation ends
                setTimeout(() => {
                    panel.classList.add('is-hidden');
                }, 300); // Match CSS transition duration
            }
        }
    }


    setSelectedToPreviousTab(currentTab) {
        var index;

        if (currentTab === this.firstTab) {
            this.setSelectedTab(this.lastTab);
        } else {
            index = this.tabs.indexOf(currentTab);
            this.setSelectedTab(this.tabs[index - 1]);
        }
    }

    setSelectedToNextTab(currentTab) {
        var index;

        if (currentTab === this.lastTab) {
            this.setSelectedTab(this.firstTab);
        } else {
            index = this.tabs.indexOf(currentTab);
            this.setSelectedTab(this.tabs[index + 1]);
        }
    }

    /* EVENT HANDLERS */

    onKeydown(event) {
        var tgt = event.currentTarget,
            flag = false;

        switch (event.key) {
            case 'ArrowLeft':
                this.setSelectedToPreviousTab(tgt);
                flag = true;
                break;

            case 'ArrowRight':
                this.setSelectedToNextTab(tgt);
                flag = true;
                break;

            case 'Home':
                this.setSelectedTab(this.firstTab);
                flag = true;
                break;

            case 'End':
                this.setSelectedTab(this.lastTab);
                flag = true;
                break;

            default:
                console.log('Unhandled key:', event.key);
                break;
        }

        if (flag) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    onClick(event) {
        this.setSelectedTab(event.currentTarget);
    }
}

const tabsAutomaticInit = () => {
    const tabLists = document.querySelectorAll('[role="tablist"]');
    tabLists && tabLists.forEach(tabList => {
        new TabsAutomatic(tabList);
    });
}

export default tabsAutomaticInit;
