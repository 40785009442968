// Import HubSpot forms script dynamically
const loadHubSpotForms = () => {
    return new Promise((resolve, reject) => {
        if (document.querySelector("script[src='https://js.hsforms.net/forms/embed/v2.js']")) {
            return resolve();
        }

        const script = document.createElement("script");
        script.src = "https://js.hsforms.net/forms/embed/v2.js";
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
    });
};

// Function to create the HubSpot form
const createHubSpotForm = () => {
    if (!window.hbspt) return;

    window.hbspt.forms.create({
        region: 'na1',
        portalId: '145712963',
        formId: 'cd1a9042-4df8-4b12-8cbf-6b44b72054e5',
        target: '#howto-access-form',
        onFormReady: function($form) {
            $form.find('input[name="form_fill_page"]').val(window.location.href);
        }
    });
};

// Function to handle form submission callback
const handleFormSubmission = (event) => {
    if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        document.cookie = "dbs_hub_content_accepted=true; path=/; max-age=" + (365 * 24 * 60 * 60);
        //window.location.reload();
        window.location.replace(window.location.href);
    }
};

// Main initialization function
const initHubSpotForm = async () => {
    const formContainer = document.querySelector('#howto-access-form');
    if (!formContainer) return;

    await loadHubSpotForms();
    createHubSpotForm();

    window.addEventListener('message', handleFormSubmission);
};

export default initHubSpotForm;
